import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import completeSvg from "../../assets/completeSVG.svg";
import searchSvg from "../../assets/search.svg";
import useHandleInitializeSearch from "../../hooks/useHandleInitializeSearch";
import DisplaySearchAndSurveyDocument from "../Land_Property_Sidebar_Actions/DisplaySearchAndSurveyDocument";
import InitiateDocumentSearchAndSurvey from "../Land_Property_Sidebar_Actions/InitiateDocumentSearchAndSurvey";
import InitiateFinalPayment from "../Land_Property_Sidebar_Actions/InitiateFinalPayment";
import EscrowHalfPayment from "../Land_Property_Sidebar_Actions/EscrowHalfPayment";
import EscrowFullPayment from "../Land_Property_Sidebar_Actions/EscrowFullPayment";
import DeedOfSaleModal from "../addons/DeedOfSaleModal";

const LandInfoSideBar = ({ land, user }) => {
  const {
    handleOpenPaymentModal,
    handleInitializeSearchSurvey,
    handleFinalPaymentVerification,
    isLoading,
    setOpenPaymentModal,
    openDeedOfSaleModal,
    handleOpenDeedOfSaleModal,
    handleCloseDeedOfSaleModal,
  } = useHandleInitializeSearch({ propertyType: "land" });

  return (
    <Grid item xs={12} sm={12} md={12} lg={4}>
      <Box
        component="div"
        className=" bg-gray-100 rounded-lg p-3 mt-1 "
        sx={{ height: "78vh" }}>
        {/* Checking if the land listed here isnt owned by the loggedIn User */}
        {(land?.lands?.UserId === user?._id &&
          land?.lands?.Listings === false) ||
        land?.lands?.UserId !== user?._id ? (
          <div className=" my-2">
            {/* Escrow Piece */}
            {land?.document?.AgentConfirmedPrice !== 0 && (
              <>
                <EscrowHalfPayment property={land} />
                <EscrowFullPayment property={land} />
              </>
            )}

            {/* Deed of sale Piece */}
            {land?.document?.FullPropertyPaymentStatus === true &&
              land?.document?.AgentDeedOfSaleStatus === true &&
              land?.document?.BuyersDeedOfSaleStatus === false && (
                <>
                  <DeedOfSaleModal
                    openDeedOfSaleModal={openDeedOfSaleModal}
                    handleCloseDeedOfSaleModal={handleCloseDeedOfSaleModal}
                    Docid={land?.document?._id}
                  />
                  <Button
                    variant="contained"
                    onClick={handleOpenDeedOfSaleModal}
                    color="info"
                    disabled={isLoading}
                    disableElevation
                    size="medium"
                    sx={{
                      fontSize: "11px",
                      width: "100%",
                      textTransform: "none",
                    }}
                    className="bg-teal-700 w-full text-white text-[12px] my-3 font-light uppercase">
                    {isLoading
                      ? "Please wait..."
                      : "Upload Signed Deed Of Sale"}
                  </Button>
                </>
              )}

            {/* Awaiting Deed of sale notification */}
            {land?.document?.FullPropertyPaymentStatus === true &&
              land?.document?.AgentDeedOfSaleStatus === true &&
              land?.document?.BuyersDeedOfSaleStatus === true &&
              land?.document?.FinalDeedOfSaleStatus === false && (
                <div className="bg-green-100 text-green-700 p-2 my-2 rounded-md text-center">
                  <h5 className="text-[10px]">
                    Draft Signed Deed of Sale Uploaded
                  </h5>
                  <div className="text-sm font-bold">
                    Awaiting Final Deed of Sale
                  </div>
                </div>
              )}

            {/* Final Deed of sale notification */}
            {land?.document?.FullPropertyPaymentStatus === true &&
              land?.document?.AgentDeedOfSaleStatus === true &&
              land?.document?.BuyersDeedOfSaleStatus === true &&
              land?.document?.FinalDeedOfSaleStatus === true && (
                <div className="bg-white text-green-700 p-2 my-2 rounded-md text-center">
                  <h5 className="text-[10px]">Final Deed of Sale Uploaded</h5>
                  <a
                    target="_blank"
                    href={`${land?.document?.FinalDeedOfSale}`}>
                    <Box className="rounded-md border py-2 px-3 mt-5  text-[#0b1d12] cursor-pointer bg-[#daf2e4]">
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}>
                        Deed of Sale Document
                      </Typography>
                    </Box>
                  </a>
                </div>
              )}

            {/* Validation Piece */}
            <div className="mt-5">
              <Stack spacing={2} direction="column">
                <>
                  {(land?.document?.SurveyStatus === "pending" &&
                    land?.document?.SurveyMessageStatus === false) ||
                  (land?.document?.Status === "pending" &&
                    land?.document?.ValidationMessageStatus === false) ? (
                    <>
                      <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                        We're actively conducting searches and survey for this
                        property. Thank you for your patience; we'll keep you
                        updated
                      </Typography>

                      <Button
                        variant="contained"
                        color="info"
                        disableElevation
                        size="medium"
                        sx={{
                          fontSize: "11px",
                          width: "100%",
                          textTransform: "none",
                        }}>
                        Search/Survey in Progress
                      </Button>
                    </>
                  ) : (land?.document?.SurveyStatus === "completed" ||
                      land?.document?.Status === "completed") &&
                    land?.document?.UnbalancedPaymentStatus === true ? (
                    <DisplaySearchAndSurveyDocument
                      land={land}
                      document={document}
                    />
                  ) : (land?.document?.SurveyStatus === "completed" ||
                      land?.document?.Status === "completed") &&
                    land?.document?.UnbalancedPaymentStatus === false ? (
                    <InitiateFinalPayment
                      isLoading={isLoading}
                      handleOpenPaymentModal={handleOpenPaymentModal}
                      handleFinalPaymentVerification={
                        handleFinalPaymentVerification
                      }
                      setOpenPaymentModal={setOpenPaymentModal}
                      document={land?.document}
                    />
                  ) : (
                    <InitiateDocumentSearchAndSurvey
                      isLoading={isLoading}
                      handleOpenPaymentModal={handleOpenPaymentModal}
                      handleInitializeSearchSurvey={
                        handleInitializeSearchSurvey
                      }
                      setOpenPaymentModal={setOpenPaymentModal}
                    />
                  )}
                </>
              </Stack>
            </div>
          </div>
        ) : (
          ""
        )}
      </Box>
    </Grid>
  );
};

export default LandInfoSideBar;
