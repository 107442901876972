import { createAsyncThunk } from "@reduxjs/toolkit";
import { logoutError } from "../auth/authService";

// export const API_URL = "http://localhost:5000/LYL";
// const API_URL = "https://lock-your-land-be.onrender.com/LYL/users/";
export const API_URL = "https://lockyourlands.uw.r.appspot.com/LYL";

const handleAsyncError = (error, thunkAPI) => {
  const message =
    (error.response &&
      error.response.data &&
      (error.response.data.message || error.response.data.error)) ||
    error.message ||
    error.toString();

  return thunkAPI.rejectWithValue(message);
};

export const createAsyncThunkWithHandler = (name, thunkFunction) =>
  createAsyncThunk(name, async (arg, thunkAPI) => {
    try {
      const result = await thunkFunction(arg, thunkAPI);

      if (result && result.status === false) {
        return thunkAPI.rejectWithValue(result.message);
      }
      return result;
    } catch (error) {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        logoutError();
        window.location.href = "/login";
      }
      return handleAsyncError(error, thunkAPI);
    }
  });
