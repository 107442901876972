import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { escrowPayment, reset } from "../features/payments/payment_slice";

const useHandleEscrowPayment = ({ docId }) => {
  const [handleOpenPaymentModal, setOpenPaymentModal] = useState(false);
  const { isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.payments
  );
  const dispatch = useDispatch();

  const handleEscrowPayment = (reference, paymentType) => {
    dispatch(escrowPayment({ docId, paymentType, reference }));
  };

  useEffect(() => {
    if (isError) {
      setOpenPaymentModal(false);
      Swal.fire({
        icon: "error",
        title: "Ooops",
        text: message,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(function () {
        dispatch(reset());
        setOpenPaymentModal(false);
      });
    }

    if (isSuccess) {
      setOpenPaymentModal(false);
      Swal.fire({
        icon: "success",
        title: "",
        text: message,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(function () {
        window.location.reload(false);
        dispatch(reset());
        setOpenPaymentModal(false);
      });
    }
  }, [isError, isSuccess, message, dispatch]);

  return {
    handleOpenPaymentModal,
    handleEscrowPayment,
    isLoading,
    setOpenPaymentModal,
  };
};

export default useHandleEscrowPayment;
