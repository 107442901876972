import { Button } from "antd";
import React from "react";
import useHandleEscrowPayment from "../../hooks/useHandleEscrowPayment";
import PaymentModal from "../addons/PaymentModal";
const EscrowFullPayment = ({ property }) => {
  const {
    handleOpenPaymentModal,
    handleEscrowPayment,
    isLoading,
    setOpenPaymentModal,
  } = useHandleEscrowPayment({ docId: property?.document?._id });

  const escrowAmount =
    property?.document?.EscrowStatus == true
      ? property?.document?.AgentConfirmedPrice -
        property?.document?.AgentConfirmedEscrowPrice
      : property?.document?.AgentConfirmedPrice;

  return (
    <>
      {property?.document?.FullPropertyPaymentStatus === true ? (
        <div className="bg-green-100 text-green-700 p-2 my-2 rounded-md text-center">
          <h5 className="text-[10px]">Full Payment Completed</h5>
          <div className="text-sm font-bold">
            ₦{escrowAmount?.toLocaleString()}
          </div>
        </div>
      ) : (
        <Button
          variant="contained"
          onClick={() => setOpenPaymentModal(!handleOpenPaymentModal)}
          color="info"
          disabled={isLoading}
          disableElevation
          size="medium"
          sx={{
            fontSize: "11px",
            width: "100%",
            textTransform: "none",
          }}
          className="bg-gray-700 w-full text-white text-[12px] my-3 font-light uppercase">
          {isLoading ? "Please wait..." : "Make Full Payment"}
        </Button>
      )}

      <PaymentModal
        amount={escrowAmount}
        handleOpenPaymentModal={handleOpenPaymentModal}
        setOpenPaymentModal={setOpenPaymentModal}
        handleEscrowPayment={handleEscrowPayment}
        escrowPaymentType="full"
      />
    </>
  );
};

export default EscrowFullPayment;
