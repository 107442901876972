import axios from "axios";
import { API_URL } from "../api";

const escrowPayment = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/escrow/payment/${data.reference}`,
    data,
    config
  );
  return response.data;
};

const paymentService = {
  escrowPayment,
};

export default paymentService;
