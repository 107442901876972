import React from "react";
import {
  Box,
  Button,
  Grid,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PaystackButton } from "react-paystack";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { deedOfSaleSchema } from "../../utils/Index";
import { uploadDeedOfSaleDocument } from "../../features/verify_documents/verify_documents_slice";

const DeedOfSaleModal = ({
  handleCloseDeedOfSaleModal,
  openDeedOfSaleModal,
  Docid,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.documents);
  const dispatch = useDispatch();

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "95%" : "500px",
    maxWidth: "600px",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: isMobile ? 2 : 4,
  };

  const { errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      deedOfSale: "",
    },
    validationSchema: deedOfSaleSchema,
    onSubmit: (values, actions) => {
      const formData = new FormData();
      formData.append("deedOfSale", values.deedOfSale);
      dispatch(
        uploadDeedOfSaleDocument({
          formData,
          Docid,
          UserId: user?._id,
        })
      );
    },
  });

  return (
    <Modal
      open={openDeedOfSaleModal}
      onClose={handleCloseDeedOfSaleModal}
      aria-labelledby="payment-modal-title"
      aria-describedby="payment-modal-description">
      <Box sx={modalStyle}>
        <Typography
          id="payment-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontWeight: "bold",
            fontSize: isMobile ? "16px" : "20px",
            mb: 2,
            color: "primary.main",
          }}>
          Uplaod Signed Deed Of Sale Document
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <h3>Deed Of Sale Upload</h3>
            <div className="border-2 p-2 ">
              <input
                id="deed of sale"
                type="file"
                style={{ display: "" }}
                accept=".pdf"
                onChange={(e) =>
                  setFieldValue("deedOfSale", e.target.files?.[0] || null)
                }
              />
            </div>

            {errors.deedOfSale && touched.deedOfSale && (
              <Typography sx={{ fontSize: "12px", color: "red" }}>
                {errors.deedOfSale}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Button
          variant="contained"
          disabled={isLoading}
          onClick={handleSubmit}
          sx={{
            bgcolor: "#1e40af",
            textTransform: "none",
            fontWeight: "bold",
            marginTop: "3%",
          }}
          className="bg-blue-700 w-full"
          size="medium"
          type="submit">
          {isLoading ? "Please wait..." : "Upload"}
        </Button>
      </Box>
    </Modal>
  );
};

export default DeedOfSaleModal;
