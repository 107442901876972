import React from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PaystackButton } from "react-paystack";
import { useSelector } from "react-redux";

const PaymentModal = ({
  handleOpenPaymentModal,
  setOpenPaymentModal,
  handleInitializeSearchSurvey,
  amount,
  finalPayment,
  handleFinalPaymentVerification,
  escrowPaymentType,
  handleEscrowPayment,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useSelector((state) => state.auth);

  const handleClose = () => setOpenPaymentModal(false);

  const publicKey = "pk_test_333758610a4ecb30c56395e15428cfcbcf296c0c";

  const successFeedbackForValidation = (response) => {
    const { reference } = response;

    // Document validation logic
    if (finalPayment) {
      handleFinalPaymentVerification(reference);
    } else {
      handleInitializeSearchSurvey(reference);
    }

    handleClose();
  };

  const successFeedbackForEscrow = (response) => {
    const { reference } = response;

    handleEscrowPayment(reference, escrowPaymentType);
    handleClose();
  };

  const Finalamount = amount * 100;
  const fullname = `${user.Firstname} ${user.Lastname}`;
  const phone = `${user.Phone || null}`;
  const email = user.Email;

  const componentProps = {
    email,
    amount: Finalamount,
    metadata: { name: fullname, phone },
    publicKey,
    text: "Proceed with payment",
    onSuccess: escrowPaymentType
      ? successFeedbackForEscrow
      : successFeedbackForValidation,
    onClose: () => {
      // Optional: Add a more subtle way to handle closing
      setOpenPaymentModal(false);
    },
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "95%" : "500px",
    maxWidth: "600px",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: isMobile ? 2 : 4,
  };

  return (
    <Modal
      open={handleOpenPaymentModal}
      onClose={handleClose}
      aria-labelledby="payment-modal-title"
      aria-describedby="payment-modal-description">
      <Box sx={modalStyle}>
        <Typography
          id="payment-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontWeight: "bold",
            fontSize: isMobile ? "16px" : "20px",
            mb: 2,
            color: "primary.main",
          }}>
          Confirm Payment
        </Typography>

        <Typography
          id="payment-modal-description"
          variant="body1"
          sx={{
            mb: 3,
            color: "text.secondary",
            lineHeight: 1.6,
          }}>
          You are about to pay a fee of ₦{amount?.toLocaleString()}. Please
          review the details before proceeding.
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: 2,
            width: "100%",
          }}>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{
              textTransform: "none",
              py: 1.5,
              borderRadius: 2,
            }}>
            Cancel
          </Button>

          <PaystackButton
            {...componentProps}
            className="w-full bg-teal-600 text-white rounded-md py-4 "
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default PaymentModal;
