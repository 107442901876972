import * as yup from "yup";

// export const url = "http://localhost:5000";

export const url = "https://lockyourlands.uw.r.appspot.com";
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,}$/;
// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

export const validateSchema = yup.object().shape({
  Email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is Required"),
  Firstname: yup
    .string()
    .min(2)
    .max(15)
    .matches(/^[A-Za-z\s]+$/, "Firstname must only contain letters")
    .required("Please enter your Firstname"),
  Lastname: yup
    .string()
    .min(2)
    .max(15)
    .matches(/^[A-Za-z\s]+$/, "Lastname must only contain letters")
    .required("Please enter your Lastname"),
  Password: yup
    .string()
    .min(8)
    .matches(passwordRules, {
      message:
        "Please create a stronger password(1 upper case letter, 1 lower case letter, 1 numeric digit)",
    })
    .required("Password is required"),
  ConfirmPassword: yup
    .string()
    .oneOf([yup.ref("Password"), null], "Passwords must match")
    .required("Please Confirm your Password"),
});

export const UpdateSchema = yup.object().shape({
  Phone: yup.number().min(9).required("Please enter your Phone Number"),
  Nin: yup.number().min(9).required("Please enter your NIN"),
  State: yup.string().min(2).max(15).required("Please enter your State"),
  Country: yup.string().min(2).max(15).required("Please enter your Country"),
  Address: yup.string().min(2).max(20).required("Please enter your Address"),
  Profile: yup.string(),
  Gender: yup.string(),
});

export const UpdatePasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required("Old password is required")

    .min(6, "Password must be at least 8 characters long"),

  newPassword: yup
    .string()
    .required("New password is required")

    .min(6, "New password must be at least 8 characters long")
    .matches(passwordRules, {
      message:
        "Please create a stronger password(1 upper case letter, 1 lower case letter, 1 numeric digit)",
    })
    .notOneOf(
      [yup.ref("oldPassword")],
      "New password cannot be the same as the old password"
    ),

  confirmNewPassword: yup
    .string()
    .required("Confirm new password is required")
    .oneOf([yup.ref("newPassword")], "Passwords must match"),
});

export const loginSchema = yup.object().shape({
  Email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is Required"),

  Password: yup
    .string()
    .min(7)
    .matches(passwordRules, {
      message:
        "Please create a stronger password(1 upper case letter, 1 lower case letter, 1 numeric digit)",
    })
    .required("Password is required"),
});

export const forgotPasswordSchema = yup.object().shape({
  Email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is Required"),
});

export const PropertySchema = yup.object().shape({
  Guarantor: yup
    .string()
    .min(2)
    .max(50)
    .matches(/^[A-Za-z\s]+$/, "Name must only contain letters")
    .required("Please enter your guarantors Name"),
  GuarantorAddress: yup.string().min(2).max(50),
  GuarantorPhone: yup
    .string()
    .required("Please enter guarantor's phone number")
    .matches(/^\d+$/, "Phone number must be digits only")
    .length(11, "Phone number must be exactly 11 digits"),
  Address: yup
    .string()
    .min(2)
    .max(80)
    .required("Please enter building Address"),
  Country: yup
    .string()
    .min(2)
    .max(50)
    .required("Please enter the country building is located"),
  State: yup
    .string()
    .min(2)
    .max(50)
    .required("Please enter the state building is located"),
  Area: yup
    .string()
    .min(2)
    .max(50)
    .required("Please enter the area building is located"),
  Currency: yup.string().required("Please select currency"),
  Measurement: yup.string().required("Please select measurement"),
  Price: yup.string().required("Please enter building Price"),
  Size: yup.string().required("Please enter building Size"),
  SittingRooms: yup.number().required("Required"),
  BedRooms: yup.number().required("Required"),
  RestRooms: yup.number().required("Required"),
  OtherRooms: yup.number().required("Required"),
  Escrow: yup.string().required("Please enter building Escrow Ammount"),
  Documents: yup.array().required("Please provide building documents"),
  Images: yup.array(),
});

export const LandSchema = yup.object().shape({
  Guarantor: yup
    .string()
    .min(2)
    .max(50)
    .matches(/^[A-Za-z\s]+$/, "Name must only contain letters")
    .required("Please enter your guarantors Name"),
  GuarantorAddress: yup.string().min(2).max(50),
  GuarantorPhone: yup
    .string()
    .required("Please enter guarantor's phone number")
    .matches(/^\d+$/, "Phone number must be digits only")
    .length(11, "Phone number must be exactly 11 digits"),

  Address: yup
    .string()
    .min(2)
    .max(80)
    .required("Please enter Property Address"),
  Country: yup
    .string()
    .min(2)
    .max(50)
    .required("Please enter the country building is located"),
  State: yup
    .string()
    .min(2)
    .max(50)
    .required("Please enter the state building is located"),
  Area: yup
    .string()
    .min(2)
    .max(50)
    .required("Please enter the area building is located"),
  Currency: yup.string().required("Please select currency"),
  Measurement: yup.string().required("Please select measurement"),
  Price: yup.string().required("Please enter Property Price"),
  Size: yup.string().required("Please enter Property Size"),
  Escrow: yup.string().required("Please enter Property Escrow Ammount"),
  Documents: yup.array().required("Please provide property documents"),
  Images: yup.array(),
});

export const SearchSchema = yup.object().shape({
  Country: yup.string().min(2).max(50).required("Please specify a country"),
  State: yup.string().min(2).max(50).required("Please specify a state"),
  Area: yup.string().min(2).max(50).required("Please specify an area"),
  Price: yup.string().required("Please enter Property Price"),
  Category: yup.string().required("Please select category"),
});

export const currencies = [
  { name: "Algerian Dinar", abbreviation: "DZD" },
  { name: "Angolan Kwanza", abbreviation: "AOA" },
  { name: "Argentine Peso", abbreviation: "ARS" },
  { name: "Australian Dollar", abbreviation: "AUD" },
  { name: "Bangladeshi Taka", abbreviation: "BDT" },
  { name: "Brazilian Real", abbreviation: "BRL" },
  { name: "Canadian Dollar", abbreviation: "CAD" },
  { name: "Chilean Peso", abbreviation: "CLP" },
  { name: "Chinese Yuan", abbreviation: "CNY" },
  { name: "Colombian Peso", abbreviation: "COP" },
  { name: "Cuban Peso", abbreviation: "CUP" },
  { name: "Czech Koruna", abbreviation: "CZK" },
  { name: "Danish Krone", abbreviation: "DKK" },
  { name: "Egyptian Pound", abbreviation: "EGP" },
  { name: "Emirati Dirham", abbreviation: "AED" },
  { name: "Euro", abbreviation: "EUR" },
  { name: "Guatemalan Quetzal", abbreviation: "GTQ" },
  { name: "Hong Kong Dollar", abbreviation: "HKD" },
  { name: "Hungarian Forint", abbreviation: "HUF" },
  { name: "Indian Rupee", abbreviation: "INR" },
  { name: "Indonesian Rupiah", abbreviation: "IDR" },
  { name: "Iraqi Dinar", abbreviation: "IQD" },
  { name: "Israeli Shekel", abbreviation: "ILS" },
  { name: "Japanese Yen", abbreviation: "JPY" },
  { name: "Jordanian Dinar", abbreviation: "JOD" },
  { name: "Kazakhstani Tenge", abbreviation: "KZT" },
  { name: "Kenyan Shilling", abbreviation: "KES" },
  { name: "Malaysian Ringgit", abbreviation: "MYR" },
  { name: "Mexican Peso", abbreviation: "MXN" },
  { name: "Moroccan Dirham", abbreviation: "MAD" },
  { name: "New Zealand Dollar", abbreviation: "NZD" },
  { name: "Nigerian Naira", abbreviation: "NGN" },
  { name: "Norwegian Krone", abbreviation: "NOK" },
  { name: "Omani Rial", abbreviation: "OMR" },
  { name: "Peruvian Sol", abbreviation: "PEN" },
  { name: "Philippine Peso", abbreviation: "PHP" },
  { name: "Polish Zloty", abbreviation: "PLN" },
  { name: "Pound Sterling", abbreviation: "GBP" },
  { name: "Qatari Riyal", abbreviation: "QAR" },
  { name: "Romanian Leu", abbreviation: "RON" },
  { name: "Russian Ruble", abbreviation: "RUB" },
  { name: "Saudi Riyal", abbreviation: "SAR" },
  { name: "Singapore Dollar", abbreviation: "SGD" },
  { name: "South African Rand", abbreviation: "ZAR" },
  { name: "South Korean Won", abbreviation: "KRW" },
  { name: "Sri Lankan Rupee", abbreviation: "LKR" },
  { name: "Sudanese Pound", abbreviation: "SDG" },
  { name: "Swedish Krona", abbreviation: "SEK" },
  { name: "Swiss Franc", abbreviation: "CHF" },
  { name: "Thai Baht", abbreviation: "THB" },
  { name: "Tunisian Dinar", abbreviation: "TND" },
  { name: "Turkish Lira", abbreviation: "TRY" },
  { name: "Ukrainian Hryvnia", abbreviation: "UAH" },
  { name: "United States Dollar", abbreviation: "USD" },
  { name: "Venezuelan Bolívar", abbreviation: "VES" },
  { name: "Vietnamese Dong", abbreviation: "VND" },
  { name: "Yemeni Rial", abbreviation: "YER" },
  { name: "Zambian Kwacha", abbreviation: "ZMW" },
];

export const measurements = [
  "Feet (ft)",
  "Meters (m)",
  "Acres",
  "Hectares",
  "Square Feet (sq ft)",
  "Square Meters (sq m)",
  "Square Kilometers (sq km)",
  "Square Miles (sq mi)",
  "Plot",
];

export const SearchPrices = [
  { value: "0 to 100000", output: "0 - 100k" },
  { value: "100000 to 500000", output: "100k - 500k" },
  { value: "500000 to 1000000", output: "500k - 1M" },
  { value: "1000000 to 5000000", output: "1M - 5M" },
  { value: "5000000 to 10000000", output: "5M - 10M" },
  { value: "10000000 to 15000000", output: "10M - 15M" },
  { value: "15000000 to 20000000", output: "15M - 20M" },
];

export const deedOfSaleSchema = yup.object().shape({
  deedOfSale: yup
    .string()
    .required("Please select upload signed deed of sale "),
});
