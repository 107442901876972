import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  addInitializePropertyValidation,
  addProperty,
  reset,
} from "../../features/property/propertySlice";
import { PropertySchema } from "../../utils/Index";
import useSaveDraft from "./local-databse/useSaveDraft";

const useSellPropertyForm = ({ verification }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError, isSuccess, message } = useSelector(
    (state) => state.property
  );
  const { loadDraft, draft, deleteDraft } = useSaveDraft();
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [firstForm, setFirstForm] = useState(true);
  const [propertyData, setPropertyData] = useState();
  const [handleOpenPaymentModal, setOpenPaymentModal] = useState(false);
  const [loading, setLoading] = useState(false);

  //Check if its loadedFrom Draft and get draftID
  const { draftId } = useParams();
  const fetchDraft = async () => {
    if (draftId) {
      await loadDraft(draftId);
    }
  };

  const formik = useFormik({
    initialValues: {
      Address: "",
      Guarantor: "",
      GuarantorAddress: "",
      GuarantorPhone: "",
      Currency: "",
      Country: "",
      State: "",
      Area: "",
      measurement: "",
      Escrow: "",
      Price: "",
      Size: "",
      BedRooms: "",
      RestRooms: "",
      OtherRooms: "",
      Images: [],
      Documents: [],
      SittingRooms: "",
    },
    validationSchema: PropertySchema,
    onSubmit: handleFormSubmit,
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  //Fetching Draft and setting the form
  useEffect(() => {
    fetchDraft();
  }, [draftId]);

  useEffect(() => {
    setImages(draft?.Images || []);
    setFiles(draft?.Documents || []);
    if (draft) {
      Object.entries(draft).forEach(([key, value]) => {
        setFieldValue(key, value);
      });
    }
  }, [draft]);

  //Handling response from api

  useEffect(() => {
    if (isError) {
      showToast(message, "error", () => {
        setSubmitting(false);
        setLoading(false);
        dispatch(reset());
      });
    }

    if (
      isSuccess &&
      (message === "Property Added Successfully" ||
        message === "Congratulations you have added a property")
    ) {
      showToast("Property Added Successfully", "info", async () => {
        await deleteDraft(draftId);
        setSubmitting(false);
        navigate(
          `${
            verification
              ? "/dashboard/verificationProperties"
              : "/dashboard/properties"
          }`
        );
        dispatch(reset());
      });
    }
  }, [isError, isSuccess, message]);

  function handleFormSubmit(values) {
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (key === "Escrow" || key === "Price") {
        formData.append(key, value.replace(/,/g, ""));
      } else if (key === "Images" || key === "Documents") {
        Array.from(value).forEach((file) => formData.append(key, file));
      } else {
        formData.append(key, value);
      }
    });

    if (verification) {
      setPropertyData(formData);
      setOpenPaymentModal(true);
    } else {
      dispatch(addProperty(formData));
    }
  }

  function handleInitializeSearchSurvey(reference) {
    dispatch(addInitializePropertyValidation({ propertyData, reference }));
    setLoading(true);
  }

  function handleCountryChange(e) {
    const { value } = e.target;
    setFieldValue("Country", value);
  }

  function handleFileUpload(event, fieldName) {
    const newFiles = Array.from(event.target.files);
    const updatedFiles =
      fieldName === "Images"
        ? [...images, ...newFiles]
        : [...files, ...newFiles];
    setFieldValue(fieldName, updatedFiles);
    if (fieldName === "Images") {
      setImages(updatedFiles);
    } else {
      setFiles(updatedFiles);
    }
  }

  function handleRemoveFile(index, fieldName) {
    const updatedFiles =
      fieldName === "Images"
        ? images.filter((_, i) => i !== index)
        : files.filter((_, i) => i !== index);
    setFieldValue(fieldName, updatedFiles);
    if (fieldName === "Images") {
      setImages(updatedFiles);
    } else {
      setFiles(updatedFiles);
    }
  }

  function formatNumber(value) {
    return value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function handlePriceChange(event, name) {
    const formattedValue = formatNumber(event.target.value);
    setFieldValue(name, formattedValue);
  }

  function showToast(message, type, onClose) {
    toast[type](message, { onClose });
  }

  return {
    handlePriceChange,
    formatNumber,
    handleFileUpload: (event) => handleFileUpload(event, "Documents"),
    handleRemoveFile: (index) => handleRemoveFile(index, "Documents"),
    handleImageUpload: (event) => handleFileUpload(event, "Images"),
    handleRemoveImage: (index) => handleRemoveFile(index, "Images"),
    handleCountryChange,
    setFieldValue,
    setSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    handleInitializeSearchSurvey,
    setOpenPaymentModal,
    handleFormSubmit,
    closeFirstForm: () => setFirstForm(!firstForm),
    formik,

    files,
    images,
    firstForm,
    selectedCountry: values.Country,
    values,
    errors,
    touched,
    isSubmitting,
    loading,
    handleOpenPaymentModal,
  };
};

export default useSellPropertyForm;
