import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import single_propertySlice from "../features/single_property/propertySlice";
import propertyReducer from "../features/property/propertySlice";
import landReducer from "../features/land/landSlice";
import singleLandReducer from "../features/single_land/landSlice";
import documentsReducer from "../features/verify_documents/verify_documents_slice";
import paymentReducer from "../features/payments/payment_slice";
import validateReducer from "../features/check_originality/check_document_originality_slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    property: propertyReducer,
    singleProperty: single_propertySlice,
    land: landReducer,
    singleLand: singleLandReducer,
    documents: documentsReducer,
    payments: paymentReducer,
    validate: validateReducer,
  },
});
