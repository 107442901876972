import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  finalizeLandVerification,
  finalizePropertyVerification,
  initializeLandVerification,
  initializePropertyVerification,
  reset,
} from "../features/verify_documents/verify_documents_slice";

const useHandleInitializeSearch = ({ propertyType }) => {
  const [handleOpenPaymentModal, setOpenPaymentModal] = useState(false);
  const { isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.documents
  );

  //Deed of sale
  const [openDeedOfSaleModal, setOpenDeedOfSaleModal] = useState(false);
  const handleOpenDeedOfSaleModal = () => setOpenDeedOfSaleModal(true);
  const handleCloseDeedOfSaleModal = () => setOpenDeedOfSaleModal(false);

  const { land } = useSelector((state) => state.singleLand);
  const { property } = useSelector((state) => state.singleProperty);

  const dispatch = useDispatch();

  const handleInitializeSearchSurvey = (reference) => {
    if (propertyType === "property") {
      const propertyId = property?.properties?._id;
      const purpose = "Verification";
      dispatch(
        initializePropertyVerification({ propertyId, purpose, reference })
      );
    } else {
      const landId = land?.lands?._id;
      const purpose = "Verification";
      dispatch(initializeLandVerification({ landId, purpose, reference }));
    }
  };

  const handleFinalPaymentVerification = (reference) => {
    if (propertyType === "property") {
      const propertyId = property?.properties?._id;
      const docId = property?.document?._id;
      dispatch(finalizePropertyVerification({ propertyId, docId, reference }));
    } else {
      const landId = land?.lands?._id;
      const docId = land?.document?._id;
      dispatch(finalizeLandVerification({ landId, docId, reference }));
    }
  };

  useEffect(() => {
    if (isError) {
      setOpenDeedOfSaleModal(false);
      setOpenPaymentModal(false);
      Swal.fire({
        icon: "error",
        title: "Ooops",
        text: message,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(function () {
        dispatch(reset());
      });
    }

    if (isSuccess) {
      setOpenPaymentModal(false);
      setOpenDeedOfSaleModal(false);
      Swal.fire({
        icon: "success",
        title: "",
        text: message,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(function () {
        window.location.reload(false);
        dispatch(reset());
      });
    }
  }, [isError, isSuccess, message, dispatch]);

  return {
    handleOpenPaymentModal,
    handleInitializeSearchSurvey,
    handleFinalPaymentVerification,
    isLoading,
    setOpenPaymentModal,
    openDeedOfSaleModal,
    handleOpenDeedOfSaleModal,
    handleCloseDeedOfSaleModal,
  };
};

export default useHandleInitializeSearch;
