import { Button } from "antd";
import React from "react";
import useHandleEscrowPayment from "../../hooks/useHandleEscrowPayment";
import PaymentModal from "../addons/PaymentModal";
const EscrowHalfPayment = ({ property }) => {
  const {
    handleOpenPaymentModal,
    handleEscrowPayment,
    isLoading,
    setOpenPaymentModal,
  } = useHandleEscrowPayment({ docId: property?.document?._id });

  const escrowAmount = property?.document?.AgentConfirmedEscrowPrice;

  return (
    <>
      {property?.document?.EscrowStatus === true ? (
        <div className="bg-green-100 text-green-700 p-2 my-2 rounded-md text-center">
          <h5 className="text-[10px]">Escrow Payment Completed</h5>
          <div className="text-sm font-bold">
            ₦{escrowAmount?.toLocaleString()}
          </div>
        </div>
      ) : (
        <>
          {property?.document?.FullPropertyPaymentStatus === false && (
            <Button
              variant="contained"
              onClick={() => setOpenPaymentModal(!handleOpenPaymentModal)}
              color="info"
              disabled={isLoading}
              disableElevation
              size="medium"
              sx={{
                fontSize: "11px",
                width: "100%",
                textTransform: "none",
              }}
              className="bg-teal-700 w-full text-white text-[12px] my-3 font-light uppercase">
              {isLoading ? "Please wait..." : "Escrow Now"}
            </Button>
          )}
        </>
      )}

      <PaymentModal
        amount={escrowAmount}
        handleOpenPaymentModal={handleOpenPaymentModal}
        setOpenPaymentModal={setOpenPaymentModal}
        handleEscrowPayment={handleEscrowPayment}
        escrowPaymentType="initial"
      />
    </>
  );
};

export default EscrowHalfPayment;
