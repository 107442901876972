import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import PaymentModal from "../addons/PaymentModal";

const InitiateDocumentSearchAndSurvey = ({
  isLoading,
  setOpenPaymentModal,
  handleOpenPaymentModal,
  handleInitializeSearchSurvey,
}) => {
  return (
    <>
      <Grid container spacing={2} direction="column" alignItems="stretch">
        <Grid item>
          <Typography className="text-[13px] font-bold">
            Initial Payment Required
          </Typography>
        </Grid>
        <Grid item>
          <Typography className="text-[12px]">
            Three searches and a survey will be completed, including:
          </Typography>
          <Box component="ul" sx={{ pl: 4 }}>
            <Typography className="text-[11px]">
              {" "}
              1. CAC search (NGN 30,000)
            </Typography>
            <Typography className="text-[11px]">
              2. Probate Registry search (NGN 30,000)
            </Typography>
            <Typography className="text-[11px]">
              3. Land Registry search and survey document (NGN 30,000)
            </Typography>
            <Typography className="text-[11px]">
              4. Initial Survey deposit (NGN 50,000)
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Typography className="text-[12px]">
            Please make an initial payment of{" "}
            <Box component="span" fontWeight="bold">
              140,000
            </Box>{" "}
            for the survey to gain full access to these documents.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            disableElevation
            disabled={isLoading}
            fullWidth
            sx={{
              bgcolor: "#000",
              color: "#fff",
              textTransform: "none",
              fontWeight: "bold",
              "&:hover": {
                bgcolor: "#333",
              },
            }}
            onClick={() => setOpenPaymentModal(!handleOpenPaymentModal)}
            size="large">
            {isLoading ? "Please wait..." : "Continue"}
          </Button>
        </Grid>
      </Grid>

      <PaymentModal
        amount={140000}
        handleOpenPaymentModal={handleOpenPaymentModal}
        setOpenPaymentModal={setOpenPaymentModal}
        handleInitializeSearchSurvey={handleInitializeSearchSurvey}
      />
    </>
  );
};

export default InitiateDocumentSearchAndSurvey;
